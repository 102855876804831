(function($) {

  

  // Reveal mobile navigation
  $('.navbar-toggler').on('click touch', function(e) {
    $('body').toggleClass('menu-open');
  });

  // Reveal mobile submenus
  $('.menu-item-has-children > a').on('click touch', function(e) {
    if ($("body").hasClass("menu-open")) {
      
      // Disable link
      e.preventDefault();

      var $this = $(this),
          $parent = $this.parent();
      $submenu = $parent.find('.sub-menu');
      $siblings = $parent.siblings().find('.sub-menu');

      // Condition block if menu has been opened
      if ($parent.hasClass('open-sub-menu')) {
        window.location.href = $this.attr('href');
      } else {
        $parent.siblings().removeClass('open-sub-menu');
      }
      if ($siblings.is(':visible')) {
          $siblings.hide(350);
      }

      // Add .menu-open when clicked
      $parent.addClass('open-sub-menu');
      $submenu.show(350);
    }
  });

  // Draw Bar Chart
  function drawCharts() {

    // by Colleges
    var queryCollegeBarChart = new google.visualization.Query('https://docs.google.com/spreadsheets/d/1kQAqSbOXepkdxxPesNIZTIrUlMZknU3P-gN8u4MD2gw/gviz/tq?sheet=Colleges&headers=1&reqId=1');
        queryCollegeBarChart.setQuery('select A, B');
        queryCollegeBarChart.send(handleCollegeBarChart);

    // by Requirement
    var queryRequirementsBarChart = new google.visualization.Query('https://docs.google.com/spreadsheets/d/1kQAqSbOXepkdxxPesNIZTIrUlMZknU3P-gN8u4MD2gw/gviz/tq?sheet=Requirements&headers=1&reqId=2');
        queryRequirementsBarChart.setQuery('select A, B');
        queryRequirementsBarChart.send(handleRequirementsBarChart);

    // pie chart
    /*
      var qsPieChart = encodeURIComponent('select A, sum(B) group by A');
      var queryPieChart = new google.visualization.Query('https://docs.google.com/spreadsheets/d/1kQAqSbOXepkdxxPesNIZTIrUlMZknU3P-gN8u4MD2gw/gviz/tq?sheet=Sheet2&headers=1&reqId=3&tq=' + qsPieChart);
      queryPieChart.send(handlePieChart);
    */
  }


  // Handler for Colleges Bar Chart
  function handleCollegeBarChart(response) {
    if (response.isError()) {
      console.log('Error in query: ' + response.getMessage() + ' ' + response.getDetailedMessage());
      return;
    }

    var data = response.getDataTable();
    var options = {
      title: '',
      chartArea:{left:20,top:0,width:'50%',height:'500'},
      vAxis: {
        title: 'Approved Courses',
      },
      isStacked: false,
      bars: 'vertical',
      bar: {groupWidth: "75%"},
      legend: {
        position: 'none',
        alignment: 'center',
        maxLines: 5
      },
      colors: ['#f5bb00','#ec9f05','#bf3100','#444444']
    };

    // Draw Bar chart
    var barchart = new google.charts.Bar(document.getElementById('colleges-barchart'));
        barchart.draw(data, google.charts.Bar.convertOptions(options));
  }


  // Handler for Requirements Bar Chart
  function handleRequirementsBarChart(response) {
    if (response.isError()) {
      console.log('Error in query: ' + response.getMessage() + ' ' + response.getDetailedMessage());
      return;
    }

    var data = response.getDataTable();
    var options = {
      title: '',
      chartArea:{left:20,top:0,width:'50%',height:'500'},
      vAxis: {
        title: 'Approved Courses',
      },
      isStacked: false,
      bars: 'vertical',
      bar: {groupWidth: "75%"},
      legend: {
        position: 'none',
        alignment: 'center',
        maxLines: 5
      },
      colors: ['#bf3100']
    };

    // Draw Bar chart
    var barchart = new google.charts.Bar(document.getElementById('requirements-barchart'));
        barchart.draw(data, google.charts.Bar.convertOptions(options));
  }


  // Handler for Bar Chart
  function handlePieChart(response) {
    console.log(response);

    if (response.isError()) {
      console.log('Error in query: ' + response.getMessage() + ' ' + response.getDetailedMessage());
      return;
    }

    var data = response.getDataTable();
    var options = {
      title: 'Totals by Subject Code',
      is3D: true
    };

    var piechart = new google.visualization.PieChart(document.getElementById('piechart'));
        piechart.draw(data, options);
  }

  // Load packages
  google.load('visualization','1', {packages:['corechart','bar'], callback: drawCharts});

})(jQuery); // Fully reference jQuery after this point.
